.container {
  display: flex;
  justify-content: center;
  background-color: #414042;
  height: 80vh;
  padding: 100px;
}

.card {
  display: flex;
  gap: 50px;
  background-color: white;
  width: 800px;
  padding: 15px;
  height: 600px;
  overflow-y: scroll;
}

#move {
  position: relative;
  right: 10px;
}

.form__container {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.image__container {
  height: 520px;
  width: 500px;

  img {
    width: 100%;
    height: 100%;
  }
}

.information__panel {
  position: relative;
  top: 50px;
  height: 800px;
  padding-bottom: 35px;

  .name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .price {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
  }

  .restriction {
    margin-bottom: 25px;
    font-weight: 500;
    color: rgb(225, 0, 0);
  }

  .color__selector {
    display: flex;
    gap: 15px;
  }

  .color__name {
    position: relative;
    bottom: 10px;
    font-weight: 400;
  }
}

.selected {
  outline: 4px solid rgb(251, 251, 6);
}

.size__selector {
  margin-bottom: 25px;
}

.color__title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}

.color__block {
  height: 35px;
  width: 40px;
  cursor: pointer;
}

.checkout__container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3c313;
  width: 135px;
  height: 30px;
  margin-top: 15px;
  border-radius: 15px;
  cursor: pointer;

  p {
    font-size: 16px;
    position: relative;
    bottom: 1px;
    font-weight: 500;
  }
}

.Black {
  background-color: black;
}

.Navy {
  background-color: #202A44;
}

.Gray {
  background-color: #808080;
}

.Graphite {
  background-color: #605D53;
}