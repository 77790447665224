.landing {
    display: flex;
    justify-content: center;
    background-color: #414042;
    height: 80vh;
}

.row {
    display: flex;
    position: relative;
    top: 100px;
    gap: 105px;
}