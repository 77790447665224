.container {
  display: flex;
  justify-content: center;
  background-color: #414042;
  padding: 100px;
}

.card {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 800px;
  padding: 15px;
  gap: 15px;
}

#move {
  position: relative;
  right: 10px;
}

#tooltip {
  position: absolute;
  right: 0px;
  top: 15px;
}

.text__field {
  width: 225px;
}

.subtotal__container {
  position: relative;
  border-top: 1px solid black;
}

.subtotal {
  position: absolute;
  font-size: 18px;
  top: 15px;
  left: 0px;
  font-weight: 500;
}

.checkout__container {
  position: relative;
  left: 350px;
  margin-top: 15px;
  width: 400px;
}