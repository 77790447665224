.size__container {
    z-index: 1;
    position: relative;
    display: flex;
    gap: 6px;
    line-height: 0;
    align-items: center;
    background-color: rgb(205, 203, 203);
    border-radius: 15px;
    height: 30px;
    width: 115px;
    padding-left: 5px;
    cursor: pointer;

    p {
        font-size: 14px;
        font-weight: 500;
    }
}

.size__container > * {
    pointer-events: none;
}

.hidden {
    visibility: hidden;
}

.icon__container {
    position: absolute;
    right: 5px;
}

.selection {
    position: absolute;
    bottom: -113px;
    display: flex;
    flex-direction: column;
    background-color: white;
    outline: 1px solid black;
    z-index: 1;
    width: 80px;
    pointer-events: all;
}

.option {
    padding-left: 10px;
}

.option:hover {
    background-color: rgb(239, 238, 238);
}